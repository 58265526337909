import React from "react";

import NumberFormat from 'react-number-format';
import Moment from 'react-moment';

export const JobLocation = props => {
    let address;
    if (props.jobData.address.city) {
        address = props.jobData.address.city + ', ' + props.jobData.address.countryCode;
    } else if (props.jobData.websitecountry) {
        address = props.jobData.websitecountry + ', ' + props.jobData.address.countryCode;
    } else {
        address = null;
    }
    return(
        <>
            {address && address}
        </>
    )
}

export const JobSalary = props => {
    return(
        <>
        {props.jobData.salary !== 0 &&
            <>
                <NumberFormat value={props.jobData.salary} displayType={'text'} thousandSeparator={true} prefix={props.jobData.currency ? props.jobData.currency : `\u00A3`} /> 
            </>
        }
        </>
    )
}

export const JobOTE = props => {
    return(
        <>
        {props.jobData.ote !== 0 &&
            <>
                <NumberFormat value={props.jobData.ote} displayType={'text'} thousandSeparator={true} prefix={props.jobData.currency ? props.jobData.currency : `\u00A3`} /> 
                <span className="job-ote-suffix">OTE</span>
            </>
        }
        </>
    )
}

export const JobType = props => {
    return(
        <>
            {props.jobData.job_type}
        </>
    )
}

export const JobDate = props => {
    return(
        <>
            <Moment format="Do MMMM YYYY">{props.jobData.createdAt}</Moment>
        </>
    )
}